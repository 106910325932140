// import { useEffect, useState } from "react";
// import { getSliders } from "../Service/Api";
import OnlineRegistration from '../Component/OnlineRegistration'
const HomeSlider = () => {
  // const [data, setData] = useState([]);
  // const [isLoading, setIsLoading] = useState(true); 

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const sliderData = await getSliders();
  //       setData(sliderData);
  //     } catch (error) {
  //       console.error("Error fetching slider data:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, []);
  // const emptyArray = [
  //   { attachment: "sld0.jpg" },
  //   { attachment: "sld1.jpg" }
  // ];
  
 
  return (
    <>
    <div id="carouselExampleControls" className="carousel slide homeslider" data-bs-ride="carousel">
    <div className="carousel-inner">
    <OnlineRegistration />
    {/* {data[0]?.attachments.length > 0 ? 
            data[0]?.attachments.map((item, index) => (
      <div className="carousel-item active" key={index}>
        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachment}`} className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/>
      </div>
      )) : 
      emptyArray.map((item, index) => (
        <div className="carousel-item active"   key={index}>
          <img src={item.attachment} className="img-fluid" alt="Delhi Police School, Wazirabad" />       
        </div>
      ))
    }
        */}
    
         
           <div className="carousel-item active"><img src="/Images/sld-1.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
           <div className="carousel-item"><img src="/Images/sld-2.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
           <div className="carousel-item"><img src="/Images/sld-3.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-4.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>      
          <div className="carousel-item"><img src="/Images/sld-5.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-6.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-7.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-8.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-9.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-10.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-11.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-12.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-13.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-14.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-15.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
          <div className="carousel-item"><img src="/Images/sld-16.jpg" className="d-block w-100" alt="Loreto Convent School Delhi Cantt"/></div>
    
    
       </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
       <span className="visually-hidden">Previous</span>
       </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
    </button>
    <div className="bd-hero-shape-wrapper d-none d-lg-block">
    <div className="bd-hero-shape bd-hero-shape-2">
    <img src="/Images/curved-line-1.png"  alt="Loreto Convent School Delhi Cantt"/>
    </div>
    <div className="bd-hero-shape bd-hero-shape-1">
    <img src="/Images/curved-line-2.png"  alt="Loreto Convent School Delhi Cantt"/>
    </div>
    </div>
    </div>
    </>
  )
}

export default HomeSlider
